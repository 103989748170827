import Model from '@/models/entities/Model'
import Surgeon from '@/models/entities/Person/Surgeon'
import Searchable from '@/models/interfaces/Searchable'
import Speciality from '@/models/entities/Speciality'
import { Expose, Exclude } from 'class-transformer'
import IStep from '@/models/interfaces/sheets/IStep'
import OtherInformation from './OtherInformation'
import PlanImage from './PlanImage'

/**
 * Defines a medical operation sheet
 */
export default class OperationSheet extends Model implements Searchable {
   @Exclude({ toPlainOnly: true }) id!: string

   @Expose() name: string = ''

   @Exclude() creationDate?: Date

   @Exclude() updateDate?: Date

   @Expose() location: string = ''

   @Expose() speciality!: Speciality

   @Expose() surgeon!: Surgeon

   @Expose() steps!: IStep[]

   @Expose() conversionId!: string

   @Expose() images!: PlanImage[] | null;

   @Exclude() viewCounter!: number

   @Expose() otherInformation!: OtherInformation[]

   pdfInformationPath!: string

   searchableContent(): string[] {
      return [this.name, this.surgeon.lastname]
   }

   sortBy(): string {
      return this.name
   }
}
