
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Tray extends Vue {
  @Prop(Boolean) readonly isOpen!: boolean

  @Prop({
    type: String,
    default: 'left',
    validator: (side: string) => ['left', 'right'].includes(side),
  })
  readonly side!: 'left' | 'right';

  get transformClasses(): string {
    return [
      ' -translate-y-6',
      'pb-20',
      'transform',
      this.side === 'left'
       ? `left-0 ${this.isOpen ? 'translate-x-24' : '-translate-x-full'}`
       : `right-0 ${this.isOpen ? '-translate-x-0' : 'translate-x-full'}`,
    ].join(' ')
  }
}
